import { FC, useRef, useState } from "react";
import { Button, Modal, Switch } from "@epcnetwork/core-ui-kit";

import { AvailableFileModel, FileStatus as FileStatusType } from "models";
import { CANCEL_TEXT } from "constants/notification.constants";
import { UsedFilesTable } from "./used-files-table/used-files-table";
import { NewFilesTable } from "./new-files-table/new-files-table";

import styles from "./attach-file.module.css";

type AttachFileModalProps = {
  onCancelClick: VoidFunction;
  onSubmitClick: (item: AvailableFileModel[]) => void;
  handleModalClose: VoidFunction;
  isModalOpen: boolean;
  initialSelectedFiles: AvailableFileModel[];
};

const AttachFileModal: FC<AttachFileModalProps> = ({
  onCancelClick,
  onSubmitClick,
  isModalOpen,
  handleModalClose,
  initialSelectedFiles,
}) => {
  const usedFilesRef = useRef<boolean>(false);

  const [usedFiles, setUsedFiles] = useState(false);
  const [selectedItems, setSelectedItems] = useState<AvailableFileModel[]>(initialSelectedFiles);

  const handleSelect = (items: AvailableFileModel[]) => {
    const lastAddedItem = items.length ? items[items.length - 1] : null;

    const completedStatuses: FileStatusType[] = [
      "file-suppression-completed",
      "global-suppression-completed",
      "asl-file-upload-completed",
    ];

    if (lastAddedItem && !completedStatuses.includes(lastAddedItem?.state)) return;
    if (items.length > selectedItems.length) {
      const selectedIds = selectedItems.map((file) => file.id);
      const newItem = items.find((item) => !selectedIds.includes(item.id));
      newItem && setSelectedItems((prevItems) => [...prevItems, newItem]);
    } else {
      const selectedIds = items.map((file) => file.id);
      const newItems = selectedItems.filter((item) => selectedIds.includes(item.id));
      setSelectedItems(newItems);
    }
  };

  const handleUsedFilesClick = (_: string, isChecked: boolean) => {
    setSelectedItems([]);
    setUsedFiles(isChecked);
    usedFilesRef.current = isChecked;
  };

  const handleCancelClick = () => onCancelClick();

  const handleSubmitClick = () => selectedItems.length && onSubmitClick(selectedItems);

  const getSelectionIndex = (item: AvailableFileModel): number => {
    return selectedItems.findIndex((file) => file.id === item.id) + 1;
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setClose={handleModalClose}
      contentClassName={styles.modalContent}
      portalClassName={styles.portal}
    >
      <div className={styles.attachFileModalControlsWrapper}>
        <div>
          <div className={styles.attachFileModalTitle}>Select file</div>
          <p className={styles.description}>
            New files will be visible automatically without reloading the page.
          </p>
        </div>
        <div className={styles.attachFileModalSwitchWrapper}>
          New files
          <Switch
            className={styles.attachFileModalSwitch}
            value="used-files"
            checked={usedFiles}
            onChange={handleUsedFilesClick}
            disableError
          />
          Used files
        </div>
      </div>

      {usedFiles && (
        <UsedFilesTable
          getSelectionIndex={getSelectionIndex}
          selectedItems={selectedItems}
          handleSelect={handleSelect}
        />
      )}
      {!usedFiles && (
        <NewFilesTable
          getSelectionIndex={getSelectionIndex}
          selectedItems={selectedItems}
          handleSelect={handleSelect}
        />
      )}

      <div className={styles.attachFileModalActionsWrap}>
        <Button appearance="secondary" onClick={handleCancelClick} className={styles.cancelButton}>
          {CANCEL_TEXT}
        </Button>
        <Button onClick={handleSubmitClick} disabled={!selectedItems.length}>
          Okay
        </Button>
      </div>
    </Modal>
  );
};

export { AttachFileModal };
