import React, { useState } from "react";
import { Button, Modal, useDidMount, useLocalStorage } from "@epcnetwork/core-ui-kit";

import { REBRANDING_HIDDEN_KEY } from "constants/rebranding.constants";

import styles from "./rebranding-modal.module.scss";

export const rebrandingNames: Record<string, string> = {
  "traffic-new.mergex.io": "waw.traffico.ai",
  "traffic-webimpact.mergex.io": "krk.traffico.ai",
  "traffic-worker.mergex.io": "bkk.traffico.ai",
  "traffic-traders.mergex.io": "ldn.traffico.ai",
};

export const RebrandingModal: React.FC = () => {
  const { value: seenRebrandingInfo, setValue } = useLocalStorage<boolean>(REBRANDING_HIDDEN_KEY);

  const [rebrandingInfoDelay, setRebrandingInfoDelay] = useState<boolean>(true);

  const handleClose = () => {
    setValue(true);
  };

  const newDomain = rebrandingNames?.[window.location.hostname] || "waw.traffico.ai";

  useDidMount(() => {
    const timeout = setTimeout(() => {
      setRebrandingInfoDelay(false);
      // set delay before rendering the rebranding navbar component
    }, 500);

    return () => clearTimeout(timeout);
  });

  return (
    <Modal
      isOpen={!seenRebrandingInfo && !rebrandingInfoDelay}
      setClose={() => null}
      contentClassName={styles.modal}
    >
      <div className={styles.content}>
        <p>
          Hey! We're changing our app's branding and domain soon. On <strong>March 1st</strong>,
          Coreg Traffic will become Traffico, and you'll need to log in at{" "}
          <strong>{newDomain}</strong>. You'll need to use your <strong>Google account</strong> to
          log in. Former username/password credentials will no longer work. If you have questions,
          please talk to your manager or contact the technology department.
        </p>
      </div>
      <div className={styles.modalButtons}>
        <Button onClick={handleClose}>Got it</Button>
      </div>
    </Modal>
  );
};
