import { FC } from "react";
import cn from "classnames";
import { TableRow, TableCell, Checkbox, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { formatDate } from "utils";
import { AvailableFileModel, FileStatus as FileStatusType } from "models";
import { timeFormat } from "constants/general.constants";
import { Badge } from "components";
import { essentialColumn } from "../../../../files/list/files-list.constants";
import { FileStatus } from "./file-status/file-status";

import globalStyles from "assets/styles/global.module.css";
import clockImg from "assets/images/clock-grey.svg";
import styles from "./attach-file-table-row.module.css";

type AttachFileTableRowProps = {
  item: AvailableFileModel;
  index: number;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const AttachFileTableRow: FC<AttachFileTableRowProps> = ({ item, index }) => {
  const { fileName, date, id, columns } = item;

  const emailColumn = essentialColumn.toLowerCase();
  const headers = columns.length ? [emailColumn, ...columns].join(", ") : emailColumn;

  const completedStatuses: FileStatusType[] = [
    "file-suppression-completed",
    "global-suppression-completed",
    "asl-file-upload-completed",
  ];

  const isCompleted = completedStatuses.includes(item.state);

  const style = { color: isCompleted ? "#000" : "#838d95" };

  return (
    <TableRow
      id={id}
      className={cn(styles.row, { [styles.disabled]: !isCompleted })}
      onRowClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <TableCell className={`${styles.checkboxCell} ${styles.override}`}>
        {isCompleted && (
          <Checkbox
            value={`file${index}`}
            onChange={noop}
            checked={!!index}
            name={`file${index}`}
            inputSize="big"
            disableError
            showBox
          />
        )}
      </TableCell>
      <TableCell className={styles.fileNameWrap}>
        <span
          className={cn(globalStyles.applyMultiOverflow, { [styles.disabled]: !isCompleted })}
          style={style}
        >
          {fileName}
        </span>
      </TableCell>
      <TableCell className={styles.statusCell}>
        <FileStatus status={item.state} />
      </TableCell>

      <TableCell>
        <Badge>
          {typeof item.initLeadsCount === "number" ? item.initLeadsCount : "-"}/
          {typeof item.finalLeadsCount === "number" ? item.finalLeadsCount : "-"}
        </Badge>
      </TableCell>

      <TableCell className={styles.headersCell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" lines={2}>
          <span style={style}>{headers}</span>
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.index}>
        <span style={style}>{index || ""}</span>
      </TableCell>
      <TableCell>
        <div style={style} className={styles.dateCell}>
          {formatDate(date, "EEE, MMM dd, yyyy")}
          <img src={clockImg} alt="" />
          {formatDate(date, timeFormat)}
        </div>
      </TableCell>
    </TableRow>
  );
};

export { AttachFileTableRow };
