import { FileStatus } from "models";
import { BadgeVariant } from "components";

export const statusDetails: Record<
  FileStatus,
  { name: string; tooltip?: string; badge: BadgeVariant }
> = {
  scheduled: {
    name: "Scheduled",
    tooltip: "File is scheduled to be processed",
    badge: "default",
  },
  "email-processing-started": {
    name: "Started",
    tooltip: "File processing started",
    badge: "default",
  },
  "file-suppression-in-progress": {
    name: "In progress",
    tooltip: "File is currently suppressing",
    badge: "default",
  },
  "file-suppression-error": {
    name: "Error",
    tooltip: "Suppression error. You will not be able to use this file.",
    badge: "error",
  },
  "file-suppression-completed": {
    name: "Completed",
    tooltip: "Suppression completed!",
    badge: "success",
  },
  "global-suppression-in-progress": {
    name: "In progress",
    tooltip: "Global suppression in progress",
    badge: "default",
  },
  "global-suppression-completed": {
    name: "Completed",
    tooltip: "Global suppression completed!",
    badge: "success",
  },
  "file-empty-after-suppression": {
    name: "File empty",
    tooltip: "File was empty after suppression. You will not be able to use this file.",
    badge: "warning",
  },
  "asl-file-upload-completed": {
    name: "Scheduled",
    tooltip: "File upload completed",
    badge: "success",
  },
  "-": {
    name: "Not applicable",
    tooltip: "",
    badge: "default",
  },
};
