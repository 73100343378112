import { memo, useState } from "react";
import {
  Search,
  Table,
  TableProps,
  Tabs,
  useDevice,
  useFetch,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";

import { findKeyByValue, getInitialStorageFilters } from "utils";
import { FileModel } from "models";
import { useAuthorization, useTablePagination } from "hooks";
import { defaultOffset } from "constants/query.constants";
import { RebrandingModal } from "components/ui/rebranding-modal/rebranding-modal";
import { Container } from "components";
import { getUploadedFiles } from "api";
import { FilesFiltersQueryType } from "./files-list.types";
import {
  initialFilters,
  TABLE_ENTITY_NAME,
  tableColumns,
  tabStatesMap,
} from "./files-list.constants";
import { FilesListTableRow } from "./files-list-table-row/files-list-table-row";
import { FileUploading } from "./file-upload/file-uploading";

import styles from "./files-list.module.css";

const FilesListPage = memo(() => {
  const ability = useAuthorization();

  const { isMobileDevice } = useDevice();
  const { query, queryString, searchValue, updateQueryParams, setSearch } =
    useFilters<FilesFiltersQueryType>(
      getInitialStorageFilters<FilesFiltersQueryType>(TABLE_ENTITY_NAME, initialFilters),
    );

  const [activeTab, setActiveTab] = useState(
    Number(findKeyByValue(tabStatesMap, query.state)) || 0,
  );

  const { payload, ...rest } = useFetch(getUploadedFiles.setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const pagination = usePagination({ listPayload: payload });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_ENTITY_NAME,
  });

  const list = payload?.data || [];
  const { total = 0, emailProcessingStarted = 0, globalSuppressionCompleted = 0 } = payload || {};

  const handleTabSelect = (index: number) => {
    updateQueryParams({
      state: tabStatesMap[index],
      offset: defaultOffset,
    });
    setActiveTab(index);
  };

  const tableProps: TableProps<FileModel[]> = {
    ...rest,
    entityName: TABLE_ENTITY_NAME,
    error: rest.error?.message,
    columns: tableColumns,
    list,
    isTabTable: true,
    resetColumnsOnMount: false,
    row: (item: FileModel, index: number) => (
      <FilesListTableRow index={index} item={item} refresh={rest.refresh} key={item.id} />
    ),
    pagination: {
      ...pagination,
      elementsPerPage: currentElementsPerPage,
      onElementsPerPageChange: handlePerPageChange,
    },
  };

  const tabs = [
    {
      tab: { name: `All Files (${total})` },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: { name: `In Process (${emailProcessingStarted})` },
      tabComponent: <Table {...tableProps} />,
    },
    {
      tab: { name: `Done (${globalSuppressionCompleted})` },
      tabComponent: <Table {...tableProps} />,
    },
  ];

  return (
    <Container contentClassName={styles.content}>
      {ability.can("create", "Jobs") && !isMobileDevice && (
        <FileUploading refreshTable={rest.refresh} />
      )}
      <Search searchValue={searchValue} setSearch={setSearch} />
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabSelect} />

      <RebrandingModal />
    </Container>
  );
});

export { FilesListPage };
