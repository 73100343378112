import { FC } from "react";
import { Button, FormField, Form, MessageField, FormButtons } from "@epcnetwork/core-ui-kit";

import { Hint } from "components";
import { useLoginHook } from "./login.hook";
import { initialValues, validationSchema } from "./login.constants";
import { LoginSidebar } from "./login-sidebar/login-sidebar";

import trafficLink from "assets/images/traffic-link.svg";
import styles from "./login.module.css";

const LoginPage: FC = () => {
  const { onSubmit, handleForgotPassword, errorMessage, hasPassChanged } = useLoginHook();

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img className={styles.icon} src={trafficLink} alt="" />
        <div className={styles.logoText}>Traffico.ai</div>
      </div>
      <LoginSidebar />
      <div className={styles.loginForm}>
        <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <div className={styles.formTitle}>Welcome to Traffico.ai</div>
          <div className={styles.formSubTitle}>Please use your credential to Log In</div>
          <MessageField message={errorMessage} align="right" />
          <FormField
            type="text"
            name="email"
            label="Email"
            inputSize="medium"
            placeholder="Fill your email"
          />
          <FormField
            type="password"
            name="password"
            label="Password"
            inputSize="medium"
            placeholder="Fill your password"
          />
          {hasPassChanged && (
            <Hint type="success">
              Your password has been reset successfully, <br />
              you can use your new password to log in.
            </Hint>
          )}
          <Button
            type="button"
            appearance="text"
            className={styles.forgotPassword}
            onClick={handleForgotPassword}
          >
            Forgot password
          </Button>
          <FormButtons>
            <Button className={styles.btn} type="submit">
              Log in
            </Button>
          </FormButtons>
        </Form>
      </div>
    </div>
  );
};

export { LoginPage };
