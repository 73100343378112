import { ColumnsType } from "types";

export const tableColumns: ColumnsType = [
  { label: "", required: true },
  { label: "File Name", required: true },
  { label: "Status", required: true },
  { label: "Initial/Final leads count" },
  { label: "Columns" },
  { label: "Order", required: true },
  { label: "Upload Date" },
];
