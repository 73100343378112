import { object, string, SchemaOf, boolean } from "yup";
import { FC, useState } from "react";
import { useFormikContext } from "formik";
import {
  Button,
  FormField,
  Select,
  notification,
  useCall,
  isEmpty,
  useDidMount,
  Tooltip,
  Label,
} from "@epcnetwork/core-ui-kit";

import { BeehiivIntegration, SelectOption } from "models";
import { requiredFieldText } from "constants/form.constants";
import { Tabs } from "components";
import { getBeehiivPublications } from "api";
import { useConfigs } from "../hooks/use-configs/use-configs.hook";
import { InitialValues } from "../../jobs-form.types";

import { Download } from "assets/images";
import mainStyles from "../connectors.module.css";
import styles from "../../job-form.module.css";

// @ts-ignore
export const beehiivSchema: SchemaOf<BeehiivType> = object({
  apiKey: string().required(requiredFieldText),
  pubId: string().required(requiredFieldText),
  reactivateExisting: boolean().required(requiredFieldText),
  sendWelcomeEmail: boolean().required(requiredFieldText),
  utmSource: string().required(requiredFieldText),
  tier: string().oneOf(["free", "premium"]).required(requiredFieldText),
});

type BeehiivListType = SelectOption<string>;

export type BeehiivType = {
  apiKey: string;
  pubId: string;
  reactivateExisting: boolean;
  sendWelcomeEmail: boolean;
  utmSource: string;
  tier: "free" | "premium";
};

type BeehiivProps = {
  endpointOrdinal: number;
};

const Beehiiv: FC<BeehiivProps> = ({ endpointOrdinal }) => {
  const { values, errors, setFieldValue, submitCount } = useFormikContext<InitialValues>();
  const { options, loading, getSelectedConfig } = useConfigs<BeehiivIntegration>("beehiiv");

  const [beehiivPublications, setBeehiivPublications] = useState<BeehiivListType[]>([]);

  const beehiivValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | BeehiivType
    | undefined;

  const { apiKey, pubId, tier } = beehiivValues || {};

  const getBeehiivPublicationsCall = useCall(getBeehiivPublications);
  getBeehiivPublicationsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check credentials and try again");

    const optionsToSave: BeehiivListType[] = payload.map(({ id, name }) => ({
      value: id ?? "",
      label: name,
    }));

    notification.success("Beehiiv publications fetched!", "Select your Beehiiv publication.");
    setBeehiivPublications(optionsToSave);
  });
  getBeehiivPublicationsCall.onCallError(() => {
    notification.error("Fetching error", "Some error happened, please try again");
  });

  const isListDisabled = !apiKey || getBeehiivPublicationsCall.submitting;

  useDidMount(() => {
    (async () => {
      if (!isListDisabled) {
        await handleGetBeehiivPublications();
      }
    })();
  });

  const handleGetBeehiivPublications = async () => {
    setBeehiivPublications([]);

    await getBeehiivPublicationsCall.submit({
      data: {
        apiKey: apiKey?.trim(),
      },
    });
  };

  const handleListSelect = async (option: SelectOption<string>) => {
    const { value } = option || {};
    await setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.pubId`, value || "");
  };

  const handleConfigChange = async ({ value }: SelectOption<number>) => {
    const config = getSelectedConfig(value);
    const propertiesField = `endpoints[${endpointOrdinal}].connector.properties`;

    if (config) {
      await setFieldValue(`${propertiesField}.apiKey`, config.properties.apiKey);
    }
  };

  const errorsAny: any = errors;
  const error =
    (!isEmpty(errorsAny) &&
      !isEmpty(errorsAny.endpoints) &&
      errorsAny?.endpoints[endpointOrdinal]?.connector?.properties?.listId) ||
    "";
  const errorText = !!submitCount && error ? error : "";

  return (
    <>
      <div className={mainStyles.configGrid}>
        <Label text="Load Beehiiv config" />
        <Select
          options={options}
          onChange={handleConfigChange}
          isSearchable
          disableClearing
          inputSize="small"
          inputClassName={mainStyles.configSelect}
          placeholder="Select Beehiiv config"
          searchPlaceholder="Search config"
          disableError
          asyncOptions={{
            loading,
          }}
        />
      </div>

      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiKey`}
        label="API Key"
        placeholder="Beehiiv API Key"
      />

      <div className={styles.listWrapper}>
        <Select
          label="List"
          placeholder="Select Beehiiv publication"
          searchPlaceholder="Search publications"
          name={`endpoints[${endpointOrdinal}].connector.properties.pubId`}
          error={errorText}
          selectedOptionsKeys={pubId}
          options={beehiivPublications}
          onChange={handleListSelect}
          disabled={isListDisabled}
          isSearchable
        />

        <Tooltip
          trigger="hover"
          triggerElement={
            <Button
              disabled={isListDisabled}
              onClick={handleGetBeehiivPublications}
              className={styles.btnDataExtensions}
            >
              <Download />
            </Button>
          }
        >
          Get Beehiiv publications
        </Tooltip>
      </div>

      <div style={{ marginBottom: "32px" }}>
        <Label text="Tier" isInputLabel />
        <Tabs
          selected={tier as string}
          onChange={({ value }) =>
            setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.tier`, value)
          }
          options={[
            { value: "free", label: "Free" },
            { value: "premium", label: "Premium" },
          ]}
        />
      </div>

      <FormField
        type="switch"
        name={`endpoints[${endpointOrdinal}].connector.properties.reactivateExisting`}
        label="Reactivate existing"
      />

      <FormField
        type="switch"
        name={`endpoints[${endpointOrdinal}].connector.properties.sendWelcomeEmail`}
        label="Send welcome email"
      />

      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.utmSource`}
        label="UTM Source"
        placeholder="UTM Source"
      />
    </>
  );
};

export { Beehiiv };
