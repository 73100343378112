import { FC, useState } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  useCall,
  notification,
  useWindowSize,
  TextEllipsis,
} from "@epcnetwork/core-ui-kit";

import { getFileStage, formatDate } from "utils";
import { FileModel } from "models";
import { useAuthorization } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { timeFormat } from "constants/general.constants";
import { Badge } from "components";
import { deleteFile } from "api";
import { errorDeleteFileTitle, essentialColumn } from "../files-list.constants";

import suppressionFileImg from "assets/images/document-with-cross-grey.svg";
import clockImg from "assets/images/clock-grey.svg";
import styles from "./files-list-table-row.module.css";

type FilesListTableRowProps = {
  index: number;
  item: FileModel;
  refresh: VoidFunction;
};

const FilesListTableRow: FC<FilesListTableRowProps> = ({ index, item, refresh }) => {
  const ability = useAuthorization();
  const { width } = useWindowSize();
  const [isDeleted, setDeleted] = useState(false);
  const {
    fileId,
    state,
    createdAt,
    initLeadsCount,
    finalLeadsCount,
    suppressedFiles,
    additionalDataHeaders,
    suppressionFiles,
    id,
  } = item;

  const { submit, onCallError, onCallSuccess } = useCall(deleteFile);

  onCallSuccess(() => {
    notification.success("Deleted file", `Successfully deleted ${fileId} file`);
    refresh();
  });

  onCallError((error) => {
    notification.error(errorDeleteFileTitle, error?.message);
    setDeleted(false);
  });

  const stage = getFileStage(state);
  const filesCount = suppressedFiles?.length || 0;

  const handleDeleteClick = () => {
    setDeleted(true);
    submit({ params: { fileId: id } });
  };

  const isMobile = width < MAX_MOBILE_WIDTH;

  if (isDeleted) {
    return null;
  }

  const emailColumn = essentialColumn.toLowerCase();

  const headers = additionalDataHeaders?.headers?.length
    ? [emailColumn, ...additionalDataHeaders.headers].join(", ")
    : emailColumn;

  return (
    <TableRow id={index}>
      <TableCell>
        <div className={styles.fileNameCell}>
          <span className={styles.fileNameCellText}>
            <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={1}>
              {fileId}
            </TextEllipsis>
          </span>
          {!!filesCount && (
            <div className={styles.additionalFile}>
              <img src={suppressionFileImg} alt="" />
              {filesCount} Suppression list attached
            </div>
          )}
        </div>
      </TableCell>
      <TableCell className={styles.suppressionFilesCell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={1}>
          {suppressionFiles?.join(", ")}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <Badge>
          {typeof initLeadsCount === "number" ? initLeadsCount : "-"}/
          {typeof finalLeadsCount === "number" ? finalLeadsCount : "-"}
        </Badge>
      </TableCell>

      <TableCell className={styles.headersCell}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {headers}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.dateCell}>
        {formatDate(createdAt, "EEE, MMM dd, yyyy")}
        <img src={clockImg} alt="" width={14} height={14} />
        {formatDate(createdAt, timeFormat)}
      </TableCell>
      <TableCell className={styles.stage}>
        <img src={stage.img} alt="" />
        {stage.name}
      </TableCell>
      <TableCell>
        {!isMobile && (
          <Menu>
            {ability.can("delete", "Jobs") && (
              <MenuButton appearance="delete" onClick={handleDeleteClick} icon="delete">
                Delete
              </MenuButton>
            )}
            {!ability.can("delete", "Jobs") && (
              <div style={{ padding: "10px" }}>No Actions available</div>
            )}
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};

export { FilesListTableRow };
