import { CSSProperties, FC, memo, useState } from "react";
import cn from "classnames";
import { Button, useDidMount, useLocalStorage, useWindowSize } from "@epcnetwork/core-ui-kit";

import { NAVBAR_REBRANDING_HIDDEN_KEY } from "constants/rebranding.constants";
import { mobileWidth } from "constants/general.constants";
import { rebrandingNames } from "components/ui/rebranding-modal/rebranding-modal";
import { collapsedDesktopWidth, sidebarWidth } from "../sidebar/sidebar.constants";
import { Sidebar } from "../sidebar/sidebar";
import { Navbar } from "../navbar";
import { useSidebar } from "./use-sidebar.hook";

import { Exclamation } from "assets/images";
import styles from "./navigation.module.css";

type NavigationProps = {
  element: FC;
  showNavigation: boolean;
};

const Navigation = memo<NavigationProps>(({ element: Component, showNavigation, ...rest }) => {
  const [rebrandingInfoDelay, setRebrandingInfoDelay] = useState<boolean>(true);

  const { value: seenNavbarRebrandingInfo, setValue } = useLocalStorage<boolean>(
    NAVBAR_REBRANDING_HIDDEN_KEY,
  );
  const { isMenuClosed, setIsMenuClosed } = useSidebar();
  const { width: windowWidth } = useWindowSize();

  const isMobile = windowWidth <= mobileWidth;

  const toggleSidebar = () => {
    setIsMenuClosed(!isMenuClosed);
  };

  const handleSidebarCollapsed = (value: boolean) => setIsMenuClosed(value);

  const hideRebrandingInfo = () => setValue(true);

  useDidMount(() => {
    const timeout = setTimeout(() => {
      setRebrandingInfoDelay(false);
      // set delay before rendering the rebranding navbar component
    }, 500);

    return () => clearTimeout(timeout);
  });

  const contentStyles = cn(styles.content, { [styles.closed]: isMenuClosed });

  const width = isMenuClosed && !isMobile ? collapsedDesktopWidth : sidebarWidth;

  const inlineStyles: CSSProperties = {
    maxWidth: `calc(100vw - ${width})`,
  };

  const newDomain = rebrandingNames?.[window.location.hostname] || "waw.traffico.ai";

  if (!showNavigation) {
    return <Component {...rest} />;
  }

  return (
    <div className={styles.container} data-testid="app">
      <Sidebar
        collapsed={isMenuClosed}
        isMobile={isMobile}
        handleSidebarCollapsed={handleSidebarCollapsed}
        toggleSidebar={toggleSidebar}
      />
      <div className={contentStyles} data-testid="content" style={inlineStyles}>
        <div
          className={cn(styles.topBar, {
            [styles.topBarHidden]: seenNavbarRebrandingInfo || rebrandingInfoDelay,
          })}
        >
          <div className={styles.row}>
            <Exclamation className={styles.icon} />
            <p>
              Starting <strong>March 1st</strong>, Coreg Traffic will become{" "}
              <strong>Traffico</strong>. The new address will be <strong>{newDomain}</strong>.
              You'll need to use your <strong>Google account</strong> to log in. Former
              username/password credentials will no longer work. If you have questions, please talk
              to your manager or contact the technology department.
            </p>
          </div>

          <Button appearance="secondary" btnSize="small" onClick={hideRebrandingInfo}>
            Got it
          </Button>
        </div>
        <Navbar toggleSidebar={toggleSidebar} isMenuClosed={isMenuClosed} />
        <Component {...rest} />
      </div>
    </div>
  );
});

export { Navigation };
