import { BeehiivIntegration } from "models";
import { BeehiivData, IntegrationData } from "api";

export const initialValues = (item: BeehiivIntegration): IntegrationData<BeehiivData> => ({
  apiType: "beehiiv",
  name: item?.name || "",
  properties: {
    apiKey: item?.properties.apiKey || "",
  },
});
