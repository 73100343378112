import React, { useRef } from "react";
import {
  Search,
  Table,
  TableContentRef,
  useCall,
  useDebounce,
  useDidUpdate,
  useQuery,
} from "@epcnetwork/core-ui-kit";

import { AvailableFileModel } from "models";
import { useInfiniteScrollFetch, DEFAULT_LIMIT } from "hooks/use-infinite-scroll-fetch";
import { BatchFileListenerEventsKeys, FileUpdateData, useBatchFileSocket } from "hooks";
import { getAvailableNewFiles } from "api";
import { tableColumns } from "../attach-file.constants";
import { AttachFileTableRow } from "../attach-file-table-row/attach-file-table-row";

import globalStyles from "assets/styles/global.module.css";
import styles from "../attach-file.module.css";

interface Props {
  getSelectionIndex: (item: AvailableFileModel) => number;
  selectedItems: AvailableFileModel[];
  handleSelect: (items: AvailableFileModel[]) => void;
}

export const NewFilesTable: React.FC<Props> = ({
  getSelectionIndex,
  selectedItems,
  handleSelect,
}) => {
  const tableContentRef = useRef<TableContentRef>(null);
  const listRef = useRef<AvailableFileModel[]>([]);

  const { socket } = useBatchFileSocket();
  const { debounce } = useDebounce(250);
  const { stringify } = useQuery();
  const { loading, list, updateItem, searchValue, setSearchValue, addItemsToList, refresh, error } =
    useInfiniteScrollFetch<typeof tableContentRef, AvailableFileModel, typeof getAvailableNewFiles>(
      {
        containerRef: tableContentRef,
        asyncApiCall: getAvailableNewFiles,
        searchDebounce: 0,
      },
    );

  const initialParams = stringify({ offset: 0, limit: DEFAULT_LIMIT });
  const { submit, onCallSuccess } = useCall(getAvailableNewFiles.setQueryParams(initialParams));
  onCallSuccess((data) => {
    if (data) {
      const newFiles = data.data;
      const currentList = listRef.current;

      const newlyAddedFiles = newFiles.filter(
        (fetchedFile) => !currentList.some((file) => file.id === fetchedFile.id),
      );

      addItemsToList(newlyAddedFiles);
    }
  });

  useDidUpdate(() => {
    listRef.current = list;
  }, [list]);

  const handleSearchChange = (value: string) => {
    debounce(() => {
      selectedItems.length && handleSelect([]);
      setSearchValue(value);
    });
  };

  useDidUpdate(
    () => {
      const handleNewFile = async () => {
        await submit();
      };

      const handleFileUpdate = (data: FileUpdateData) => {
        updateItem(data.id, data);
      };

      if (socket) {
        socket.on<BatchFileListenerEventsKeys>("newBatchFile", handleNewFile);
        socket.on<BatchFileListenerEventsKeys>("batchFileUpdate", handleFileUpdate);

        return () => {
          socket.off<BatchFileListenerEventsKeys>("newBatchFile", handleNewFile);
          socket.off<BatchFileListenerEventsKeys>("batchFileUpdate", handleFileUpdate);
        };
      }
    },
    [socket],
    true,
  );

  return (
    <>
      <div className={styles.attachFileModalControlsWrapper}>
        <Search
          className={styles.attachFileModalSearch}
          searchValue={searchValue}
          setSearch={handleSearchChange}
        />
      </div>
      <div className={`${styles.attachFileModalTableWrap} ${globalStyles.addScrollStyles}`}>
        <Table
          entityName="job-attach-file-modal"
          contentClassName={styles.attachFileModalTable}
          columns={tableColumns}
          list={list}
          refresh={refresh}
          loading={loading}
          multiSelect={true}
          error={error?.message}
          itemIdKey="id"
          row={(item) => <AttachFileTableRow item={item} index={getSelectionIndex(item)} />}
          onSelect={handleSelect}
          selectedList={selectedItems}
          sharedTableContentRef={tableContentRef}
        />
      </div>
    </>
  );
};
