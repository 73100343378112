import React, { useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { FormField, Label, SelectOption, useDidMount, useDidUpdate } from "@epcnetwork/core-ui-kit";

import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { FileTabButton } from "components/ui/file-tabs";
import { TabOption } from "components";
import { AweberType } from "../aweber.types";
import { getInitialAweberName } from "./aweber-name.constants";

import styles from "./aweber-name.module.scss";

interface Props {
  endpointOrdinal: number;
}

export const AweberName: React.FC<Props> = ({ endpointOrdinal }) => {
  const [editedFileIndex, setEditedFileIndex] = useState<number>(0);

  const { values, setFieldValue } = useFormikContext<InitialValues>();

  const marketoValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | AweberType
    | undefined;
  const { names } = marketoValues || {};

  const getFormName = (index: number) => (suffix: string) =>
    `endpoints[${endpointOrdinal}].connector.properties.names[${index}]${suffix}`;

  useDidMount(() => {
    if (names?.length === 0) {
      setFieldValue(
        `endpoints[${endpointOrdinal}].connector.properties.names`,
        getInitialAweberName(values.attachedFiles),
      );
    }
  });

  useDidUpdate(() => {
    // set new values when files change
    setEditedFileIndex(0);
    setFieldValue(
      `endpoints[${endpointOrdinal}].connector.properties.names`,
      getInitialAweberName(values.attachedFiles),
    );
  }, [values.attachedFiles]);

  const fileTabOptions: TabOption<number>[] = useMemo(() => {
    return values.attachedFiles.map((file, index) => ({ label: file.fileName, value: index }));
  }, [values.attachedFiles]);

  const fileColumns: SelectOption<string>[] = useMemo(() => {
    if (!values.attachedFiles?.[editedFileIndex]) return [];

    const columns: SelectOption<string>[] = values.attachedFiles?.[editedFileIndex]?.columns.map(
      (column) => ({
        label: column,
        value: column,
      }),
    );

    return columns;
  }, [values.attachedFiles, editedFileIndex]);

  const haveFilesSelected = values.attachedFiles.length > 0;

  return (
    <div>
      <p className={styles.mainLabel}>Name column</p>
      <p className={styles.description}>Select name column for each file</p>
      {!haveFilesSelected && (
        <p className={styles.description}>You need to select a file to set a columns</p>
      )}
      {haveFilesSelected && (
        <div>
          <div className={styles.tabsWrapper}>
            {fileTabOptions.map((option, index) => {
              return (
                <FileTabButton
                  key={option.value}
                  selected={option.value === editedFileIndex}
                  label={option.label}
                  isValid
                  onClick={() => setEditedFileIndex(index)}
                />
              );
            })}
          </div>

          <div className={styles.content}>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>
                <Label text="Name" isInputLabel isRequired={false} />
                <p className={styles.projectDescription}>Select name column</p>
              </div>
              <FormField
                type="select"
                label=""
                required
                inputSize="small"
                placeholder="Select column"
                name={getFormName(editedFileIndex)(".column")}
                options={fileColumns}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
