import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { Button } from "@epcnetwork/core-ui-kit";

import { LOGIN_PAGE } from "constants/routes.constants";
import { LoginSidebar } from "../../login/login-sidebar/login-sidebar";

import trafficLink from "assets/images/traffic-link.svg";
import styles from "../send-email/send-email.module.css";

const ForgotPasswordHeader: FC = () => {
  const navigate = useNavigate();
  const moveToLogin = () => navigate(LOGIN_PAGE.path);

  return (
    <>
      <div className={styles.logo}>
        <img className={styles.icon} src={trafficLink} alt="" />
        <div className={styles.logoText}>Traffico.ai</div>
        <div className={styles.topBar}>
          <span className={styles.topBarText}>Remembered your credentials?</span>
          <Button appearance="text" className={styles.link} onClick={moveToLogin}>
            Log In
          </Button>
        </div>
      </div>
      <LoginSidebar />
    </>
  );
};

export { ForgotPasswordHeader };
