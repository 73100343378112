import React from "react";
import { Tooltip, Loader } from "@epcnetwork/core-ui-kit";

import { FileStatus as FileStatusType } from "models";
import { Badge } from "components";
import { statusDetails } from "./file-status.constants";

import { Check, Exclamation } from "assets/images";
import styles from "../attach-file-table-row.module.css";

interface Props {
  status: FileStatusType;
}

export const FileStatus: React.FC<Props> = ({ status }) => {
  const spinnerStatuses: FileStatusType[] = [
    "file-suppression-in-progress",
    "global-suppression-in-progress",
  ];
  const warningStatuses: FileStatusType[] = [
    "file-empty-after-suppression",
    "file-suppression-error",
  ];
  const successStatuses: FileStatusType[] = [
    "file-suppression-completed",
    "global-suppression-completed",
    "asl-file-upload-completed",
  ];

  const showSpinner = spinnerStatuses.includes(status);
  const showWarning = warningStatuses.includes(status);
  const showSuccess = successStatuses.includes(status);

  return (
    <Tooltip
      trigger="hover"
      triggerElement={
        <Badge variant={statusDetails[status].badge} className={showSpinner ? styles.badge : ""}>
          <span className={styles.text}>{statusDetails[status].name}</span>
          {showSpinner && <Loader type="clip-loader" loaderClassName={styles.loader} />}
          {showWarning && <Exclamation />}
          {showSuccess && <Check />}
        </Badge>
      }
    >
      {statusDetails[status].tooltip}
    </Tooltip>
  );
};
